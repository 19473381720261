<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">10A</h3>
          <p class="font-weight-bold text-colorGray mb-50">
            Development (IT & Software)
          </p>
        </b-col>
      </b-row>
      <b-row class="px-2 w-100">
        <b-col md="12">
          <b-tabs content-class="mt-50">
            <b-tab class="pl-1" active>
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack">Subjects</span>
              </template>
              <b-row class="d-flex justify-content-between mt-2 px-2">
                <b-col
                  md="12"
                  class="bg-colorWhite py-2"
                  style="border-radius: 20px"
                >
                  <b-card-group deck class="d-flex justify-content-between">
                    <b-link :to="{ name: 'InstructorSubjectDetail' }">
                      <b-card
                        img-src="@/assets/images/icons/subject/subject.png"
                        img-alt="Image"
                        img-top
                      >
                        <b-card-title class="text-colorBlack font-weight-bolder"
                          >Math</b-card-title
                        >
                        <b-card-text class="text-colorGray font-weight-bold">
                          Code4545
                        </b-card-text>
                        <b-card-text class="text-colorGray font-weight-bold">
                          By Clark Maxwell
                        </b-card-text>
                      </b-card>
                    </b-link>
                    <b-link :to="{ name: 'InstructorSubjectDetail' }">
                      <b-card
                        img-src="@/assets/images/icons/subject/subject.png"
                        img-alt="Image"
                        img-top
                      >
                        <b-card-title class="text-colorBlack font-weight-bolder"
                          >Math</b-card-title
                        >
                        <b-card-text class="text-colorGray font-weight-bold">
                          Code4545
                        </b-card-text>
                        <b-card-text class="text-colorGray font-weight-bold">
                          By Clark Maxwell
                        </b-card-text>
                      </b-card>
                    </b-link>
                    <b-link :to="{ name: 'InstructorSubjectDetail' }">
                      <b-card
                        img-src="@/assets/images/icons/subject/subject.png"
                        img-alt="Image"
                        img-top
                      >
                        <b-card-title class="text-colorBlack font-weight-bolder"
                          >Math</b-card-title
                        >
                        <b-card-text class="text-colorGray font-weight-bold">
                          Code4545
                        </b-card-text>
                        <b-card-text class="text-colorGray font-weight-bold">
                          By Clark Maxwell
                        </b-card-text>
                      </b-card>
                    </b-link>
                    <b-link :to="{ name: 'InstructorSubjectDetail' }">
                      <b-card
                        img-src="@/assets/images/icons/subject/subject.png"
                        img-alt="Image"
                        img-top
                      >
                        <b-card-title class="text-colorBlack font-weight-bolder"
                          >Math</b-card-title
                        >
                        <b-card-text class="text-colorGray font-weight-bold">
                          Code4545
                        </b-card-text>
                        <b-card-text class="text-colorGray font-weight-bold">
                          By Clark Maxwell
                        </b-card-text>
                      </b-card>
                    </b-link>
                  </b-card-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack">Students</span>
              </template>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudent"
                  :items="Student"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Name)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Name ? row.item.Name : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(Created)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.Created ? row.item.Created : "" }}
                    </span>
                  </template>

                  <template #cell(Status)="row">
                    <span
                      class="font-weight-bolder text-colorBlack"
                      style="font-size: 0.8rem"
                    >
                    </span>
                    <b-badge
                      pill
                      size="sm"
                      variant="colorBlue"
                      v-if="row.item.Status === 'Active'"
                    >
                      {{ row.item.Status ? row.item.Status : "" }}
                    </b-badge>
                    <b-badge
                      pill
                      size="sm"
                      variant="danger"
                      v-if="row.item.Status === 'Inactive'"
                    >
                      {{ row.item.Status ? row.item.Status : "" }}
                    </b-badge>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      fieldsStudent: [
        { key: "Name", label: "Name" },
        { key: "Created", label: "Created" },
        { key: "Status", label: "Status" },
      ],
      Student: [
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Active",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Active",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Inactive",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Active",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Inactive",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Active",
        },
      ],
      subjectCode: "",
      value: 20,
      max: 100,
    };
  },
  methods: {},
};
</script>

<style>
.tableHeadColor,
.table thead th,
thead,
th {
  background-color: #ebf0fc !important;
}
</style>
